export const transformAuthorizedCompany = (data) => {
    return Object.values(
        data.reduce((obj, el) => {
            const accessPoint = el.monitoringObject?.id && {
                ...el.monitoringObject,
                objectName: `${el.monitoringObject?.objectType} - ${el.monitoringObject?.name}`,
            };

            const modifiedAuthorizedCompany = {
                ...el,
                id: el.authorizedOrganization.id,
                timestamp: `${el.created.slice(0, 10)} ${el.created.slice(11, 16)}`,
                phoneNumber: el.accessOrganizationInfo?.phoneNumber,
                email: el.accessOrganizationInfo?.email,
                name: el.authorizedOrganization.name,
                clientType: el.authorizedOrganization?.clientType,
                commonName: el.authorizedOrganization?.commonName,
                industryType: el.authorizedOrganization?.industryType,
                position: el.authorizedOrganization?.position,
                alerts: !!el.authType?.color,
                authorizedBy: '',
                authorizedByInfo: el.authorizedBy,
                note: el.note || '',
                authTypes: [],
                organization: el.organization ?? el.location?.organization,
                accessPoint,
                type: el.accessOrganizationInfo?.type,
            };

            const updatedAuthType = {
                ...modifiedAuthorizedCompany.authType,
                organizationId: el.id,
                authorizedBy: modifiedAuthorizedCompany.authorizedByInfo,
                note: modifiedAuthorizedCompany.note,
                modified: modifiedAuthorizedCompany.modified,
            };

            modifiedAuthorizedCompany.authType = updatedAuthType;
            modifiedAuthorizedCompany.authTypes = [updatedAuthType];

            if (obj[modifiedAuthorizedCompany.id]) {
                obj[modifiedAuthorizedCompany.id].authTypes = [...obj[modifiedAuthorizedCompany.id].authTypes, updatedAuthType];
            } else {
                obj[modifiedAuthorizedCompany.id] = { ...modifiedAuthorizedCompany };
            }

            return obj;
        }, {})
    );
};

export const createPayloadMapper = (data) => {
    const location = {
        id: data.locationId,
    };
    const accessPoint = {
        id: data.accessPoint,
    };
    return {
        organization: {
            name: data.name,
            address: data.address,
            industryType: data.industryType,
            clientType: 'NON_CLIENT',
        },
        accessOrganizationInfo: {
            email: data.email,
            type: data.type,
            phoneNumber: data.phoneNumber,
            location,
            accessPoint,
            limitedPeriodAccess: { ...data.limitedPeriodAccess, location, monitoringObject: accessPoint },
        },
        accessTypeList: data.alertRows,
    };
};
