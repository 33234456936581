import { ROLES } from 'utils/constants';
import ShieldIcon from '@mui/icons-material/Shield';
import KeyIcon from '@mui/icons-material/Key';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import ListAltIcon from '@mui/icons-material/ListAlt';

export const routes = [
    {
        allowedRoles: [ROLES.ADMIN, ROLES.HR, ROLES.AGENT, ROLES.HQ_SUPPORT, ROLES.USER, ROLES.TECH, ROLES.SALES],
        path: '/',
        text: 'Homepage',
    },
    {
        allowedRoles: [ROLES.ADMIN, ROLES.FACILITY_MANAGER],
        path: '/access-point-record-list',
        text: 'Access Point Records',
    },
    {
        allowedRoles: [ROLES.ADMIN, ROLES.HR, ROLES.USER],
        path: '/employees',
        text: 'Employees',
    },
    {
        text: 'Authorized Lists',
        Icon: ShieldIcon,
        allowedRoles: [ROLES.ADMIN, ROLES.AGENT, ROLES.FACILITY_MANAGER],
        items: [
            {
                Icon: HowToRegIcon,
                path: '/people-authorized-list',
                text: 'People',
            },
            {
                Icon: ListAltIcon,
                path: '/company-authorized-list',
                text: 'Organizations',
            },
        ],
    },
    {
        allowedRoles: [ROLES.ADMIN],
        Icon: KeyIcon,
        path: '/authorized-organization-locations',
        text: 'Authorized Organizations & Locations',
    },
    {
        allowedRoles: [ROLES.ADMIN, ROLES.USER, ROLES.TECH],
        path: '/monitoring-elements',
        text: 'Monitoring Elements',
    },
    {
        allowedRoles: [ROLES.ADMIN, ROLES.USER, ROLES.HQ_SUPPORT, ROLES.SALES],
        path: '/organization-list',
        text: 'Organizations',
    },
    {
        allowedRoles: [ROLES.ADMIN, ROLES.USER, ROLES.HQ_SUPPORT, ROLES.SALES],
        path: '/location-list',
        text: 'Locations',
    },
    {
        allowedRoles: [ROLES.ADMIN, ROLES.USER, ROLES.HQ_SUPPORT, ROLES.SALES],
        path: '/divison-list',
        text: 'Divisions',
    },
    {
        allowedRoles: [ROLES.ADMIN, ROLES.USER, ROLES.TECH],
        path: '/camera-tron',
        text: 'CameraTron',
    },
    {
        allowedRoles: [ROLES.ADMIN, ROLES.USER, ROLES.TECH],
        path: '/control-center',
        text: 'Control Center',
    },
    {
        allowedRoles: [ROLES.ADMIN, ROLES.USER, ROLES.TECH],
        path: '/monitoring-object',
        text: 'Monitoring Object',
    },
    {
        text: 'Alisa',
        items: [{ path: '/regions-of-interest', text: 'Regions Of Interest' }],
    },
];
