export const transformAuthorizedPersons = (data) => {
    return Object.values(
        data.reduce((obj, el) => {
            const accessPoint = el.monitoringObject?.id && {
                ...el.monitoringObject,
                objectName: `${el.monitoringObject.objectType} - ${el.monitoringObject.name}`,
            };

            const modifiedAuthorizedPerson = {
                ...el,
                id: el.authorizedPerson.id,
                timestamp: `${el.created.slice(0, 10)} ${el.created.slice(11, 16)}`,
                firstName: el.authorizedPerson.firstName,
                lastName: el.authorizedPerson.lastName,
                position: el.authorizedPerson.position,
                phoneNumber: el.authorizedPerson.phoneNumber,
                dl: el.authorizedPerson.dl,
                cdl: el.authorizedPerson.cdl,
                companyCardNumber: el.authorizedPerson.companyCardNumber,
                alerts: !!el.authType?.color,
                authorizedBy: '',
                authorizedByInfo: el.authorizedBy,
                note: el.note || '',
                authTypes: [],
                organization: el.organization ?? el.location?.organization,
                accessPoint,
                dateOfBirth: el.authorizedPerson.dateOfBirth,
            };

            const updatedAuthType = {
                ...modifiedAuthorizedPerson.authType,
                personnelId: el.id,
                authorizedBy: modifiedAuthorizedPerson.authorizedByInfo,
                note: modifiedAuthorizedPerson.note,
                modified: modifiedAuthorizedPerson.modified,
            };

            modifiedAuthorizedPerson.authType = updatedAuthType;
            modifiedAuthorizedPerson.authTypes = [updatedAuthType];

            if (obj[modifiedAuthorizedPerson.id]) {
                obj[modifiedAuthorizedPerson.id].authTypes = [...obj[modifiedAuthorizedPerson.id].authTypes, updatedAuthType];
            } else {
                obj[modifiedAuthorizedPerson.id] = { ...modifiedAuthorizedPerson };
            }

            return obj;
        }, {})
    );
};
