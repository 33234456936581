import { useDispatch, useSelector } from 'react-redux';
import {
    fetchAccessPointRecordsStart,
    fetchAccessPointRecordsColumnStart,
    setSearchText,
    setFilterDateRange,
    setSelectedFilter,
    setAllFilter,
    removeAccessPointRecordsStart,
} from '../store/accessPointRecordsSlice';
import { useCallback, useMemo } from 'react';
import {
    selectAccessPointRecordsColumnList,
    selectAccessPointRecordsDataByTableId,
    selectAccessPointRecordsFilterDateRange,
    selectFetchingAccessPointRecordsColumnError,
    selectFetchingAccessPointRecordsColumnInProgress,
    selectFetchingAccessPointRecordsError,
    selectFetchingAccessPointRecordsInProgress,
    selectSearchText,
    selectSelectedFilter,
    selectAllFilter,
    selectRemovingAccessPointRecordInProgress,
    selectRemovingAccessPointRecordError,
} from '../store/accessPointRecordsSelectors';
import { ACCESS_POINT_RECORDS_TABLE_ID } from '../constants/accessPointRecordTableConstant';

const useAccessPointRecord = () => {
    const dispatch = useDispatch();

    const dispatchFetchAccessPointRecords = useCallback(
        (locationId) => {
            dispatch(fetchAccessPointRecordsStart({ locationId }));
        },
        [dispatch]
    );

    const dispatchFetchAccessPointRecordsColumn = useCallback(
        (locationId) => {
            dispatch(fetchAccessPointRecordsColumnStart({ locationId }));
        },
        [dispatch]
    );

    const dispatchSetDateRange = useCallback(
        (dateRange) => {
            dispatch(
                setFilterDateRange({
                    startDate: dateRange.startDate?.toISOString(),
                    endDate: dateRange.endDate?.toISOString(),
                })
            );
        },
        [dispatch]
    );

    const dispatchSetSearchText = useCallback(
        (text) => {
            dispatch(setSearchText(text));
        },
        [dispatch]
    );

    const dispatchSetSelectedFilter = useCallback(
        (filter) => {
            dispatch(setSelectedFilter(filter));
        },
        [dispatch]
    );
    const dispatchSetAllFilter = useCallback(
        (filters) => {
            dispatch(setAllFilter(filters));
        },
        [dispatch]
    );

    const dispatchRemoveAccessPointRecord = useCallback(
        (locationId) => {
            dispatch(removeAccessPointRecordsStart({ locationId }));
        },
        [dispatch]
    );

    const fetchingAccessPointRecordsInProgress = useSelector(selectFetchingAccessPointRecordsInProgress);
    const fetchingAccessPointRecordsError = useSelector(selectFetchingAccessPointRecordsError);

    const fetchingAccessPointRecordsColumnInProgress = useSelector(selectFetchingAccessPointRecordsColumnInProgress);
    const fetchingAccessPointRecordsColumnError = useSelector(selectFetchingAccessPointRecordsColumnError);

    const accessPointRecordsColumnList = useSelector(selectAccessPointRecordsColumnList);
    const selectAccessPointRecordsSelector = useMemo(() => selectAccessPointRecordsDataByTableId(ACCESS_POINT_RECORDS_TABLE_ID), []);
    const { tableData } = useSelector(selectAccessPointRecordsSelector);
    const filterDateRange = useSelector(selectAccessPointRecordsFilterDateRange);
    const searchText = useSelector(selectSearchText);
    const selectedFilter = useSelector(selectSelectedFilter);
    const allFilter = useSelector(selectAllFilter);

    const removingAccessPointRecordInProgress = useSelector(selectRemovingAccessPointRecordInProgress);
    const removingAccessPointRecordError = useSelector(selectRemovingAccessPointRecordError);

    return {
        fetchAccessPointRecords: dispatchFetchAccessPointRecords,
        fetchAccessPointRecordsColumn: dispatchFetchAccessPointRecordsColumn,
        dispatchSetSearchText,
        dispatchSetSelectedFilter,
        dispatchSetAllFilter,
        fetchingAccessPointRecordsInProgress,
        fetchingAccessPointRecordsError,
        accessPointRecordsList: tableData,
        fetchingAccessPointRecordsColumnInProgress,
        fetchingAccessPointRecordsColumnError,
        accessPointRecordsColumnList,

        filterDateRange,
        setFilterDateRange: dispatchSetDateRange,
        searchText,
        selectedFilter,
        allFilter,
        removeAccessPointRecord: dispatchRemoveAccessPointRecord,
        removingAccessPointRecordInProgress,
        removingAccessPointRecordError,
    };
};

export default useAccessPointRecord;
