import { configureStore } from '@reduxjs/toolkit';
//sagas
import createSagaMiddleware from '@redux-saga/core';
import rootSaga from 'store/rootSaga';

//reducers
import { snackbarsReducer } from './snackbars/snackbarsSlice';
import { formControlReducer } from 'store/formControl/formControlSlice';
import { employeeReducer } from './employees/empoloyeeSlice';
import { peopleAuthorizedListReducer } from './peopleAuthorizedList/peopleAuthorizedListSlice';
import { tableControlReducer } from './tableControlSlice/tableControlSlice';
import { companyAuthorizationListReducer } from './companyAuthorizedList/companyAuthorizationListSlice';
import { organizationListReducer } from './organizationList/organizationListSlice';
import { locationListReducer } from './locationList/locationListSlice';
import { cameraInfoReducer } from './cameraInfo/cameraInfoSlice';
import { divisionListReducer } from './divisionList/divisionListSlice';
import { monitoringElementReducer } from './MonitoringElement/monitoringElementSlice';
import { controlCentarReducer } from './controlCentar/controlCentarSlice';
import { monitoringObjectReducer } from './monitoringObject/monitoringObjectSlice';
import { authReducer } from './auth/authSlice';
import { globalLocationReducer } from 'components/globalLocationSelector/store/globalLocationSlice.js';
import { accessPointRecordsReducer } from '../components/accessPointRecords/store/accessPointRecordsSlice';
import { userLocationsReducer } from 'features/userLocations/store/userLocationsSlice';

let sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

const store = configureStore({
    reducer: {
        auth: authReducer,
        snackbars: snackbarsReducer,
        forms: formControlReducer,
        employee: employeeReducer,
        peopleAuthorizedList: peopleAuthorizedListReducer,
        tables: tableControlReducer,
        companyAuthorizationList: companyAuthorizationListReducer,
        cameraInfo: cameraInfoReducer,
        organizationList: organizationListReducer,
        locationList: locationListReducer,
        divisionList: divisionListReducer,
        monitoringElementList: monitoringElementReducer,
        controlCentarList: controlCentarReducer,
        monitoringObjectList: monitoringObjectReducer,
        globalLocationList: globalLocationReducer,
        accessPointRecordsList: accessPointRecordsReducer,
        userLocationsList: userLocationsReducer,
    },
    devTools: import.meta.env.VITE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    'employee/submitFormFailed',
                    'employee/submitFormStart',
                    'peopleAuthorizedList/submitFormStart',
                    'peopleAuthorizedList/addPerson',
                    'peopleAuthorizedList/addAuthorizedPerson',
                    'companyAuthorizedList/addItemToAuthorizedList',
                    'organizationList/addItemToMasterList',
                    'organizationList/submitFormStart',
                    'monitoringObjectList/submitFormStart',
                    'divisionList/addItemToAuthorizedList',
                    'divisionList/addItemToMasterList',
                    'locationList/submitFormStart',
                ],
            },
        }).concat(middleware),
});

sagaMiddleware.run(rootSaga);

export default store;
