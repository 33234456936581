import { isValid, format } from 'date-fns';

const DATE_FORMAT = 'MM/dd/yyyy';

const DATE_TIME_FORMAT = 'MM/dd/yyyy hh:mma';

export const fromIsoString = (isoString) => {
    const date = new Date(isoString);
    return isValid(date) ? date : null;
};

export const isValidDate = (date) => {
    return isValid(date);
};

export const formatDate = (date, dateFormat = undefined) => {
    return format(date, dateFormat || DATE_FORMAT);
};

export const formatDateTime = (dateTime) => {
    return format(dateTime, DATE_TIME_FORMAT);
};
