export const ROLES = {
    ADMIN: 'admin',
    AGENT: 'agent',
    USER: 'user',
    HR: 'hr',
    HQ_SUPPORT: 'hq_support',
    FACILITY_MANAGER: 'facility_manager',
    SALES: 'sales',
    TECH: 'tech',
};

export const USER_STATUS = {
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
};

export const PEOPLE_TITLE_LIST = [
    'Accountant',
    'Cleaner',
    'Cleaning Lady',
    'Customer Service Department',
    'Dispatcher',
    'Dock Operator',
    'Dock Supervisor',
    'Driver',
    'Driver (Owner Operator)',
    'Driver Instructor',
    'Electrician',
    'Equipment Manager',
    'GPS Installer',
    'Guard',
    'Maintenance',
    'Manager',
    'Mechanic',
    'Office Staff',
    'Operations Manager',
    'Owner',
    'Partner',
    'Property Manager',
    'Repair',
    'Safety & Compliance Co-Ordinator',
    'Safety Manager',
    'Secretary',
    'Shop Manager',
    'Shop Mechanic',
    'Shunt Driver',
    'Tech',
    'Vendor',
    'Warehouse Personnel',
    'Washer',
    'Worker',
    'Yard Manager',
];

export const PERIOD_ACCESS_TYPES = {
    ONE_TIME: 'ONE_TIME',
    DATE_PERIOD: 'DATE_PERIOD',
    TIME_PERIOD: 'TIME_PERIOD',
    WEEKDAYS: 'WEEKDAYS',
};

export const PERIOD_ACCESS_TYPE_NAMES = {
    [PERIOD_ACCESS_TYPES.ONE_TIME]: 'One Time',
    [PERIOD_ACCESS_TYPES.DATE_PERIOD]: 'Date Period',
    [PERIOD_ACCESS_TYPES.TIME_PERIOD]: 'Time Period',
    [PERIOD_ACCESS_TYPES.WEEKDAYS]: 'Weekdays',
};

export const INDUSTRY_LIST = ['AUTOMOTIVE', 'TRUCKING', 'RESIDENTAL'];
