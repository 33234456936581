import { axiosLasta } from 'config';

export const fetchOrganizationLocationsByOrganizationId = async (id) => {
    const response = await axiosLasta.get(`/location/organization/${id}`, {
        params: {
            status: true,
        },
    });
    const { data } = response;
    return data;
};
